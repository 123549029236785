"use client";

import type { Payload } from "@local/payload-client/src/types";
import Image from "@/components/Image";
import ButtonServer, { ButtonIntent, ButtonStretch } from "../ui/button";
import Link from "next/link";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
import { barlowCondensed } from "@/utils/fonts";
import { getPrice } from "@/lib/getPrice";
import { Price, useI10n } from "@local/i10n";
import { classNames } from "@local/utils";
import styles from "./styles.module.css";
type BestSellersProductCard = {
  image?: Payload.Media;
  product?: Payload.Product;
  id?: string;
};
export const BestSellersSingleBlock = ({
  product,
  index,
  isVisible,
  vertical
}: {
  product: BestSellersProductCard;
  index: number;
  isVisible: boolean;
  vertical?: boolean;
}) => {
  const {
    currencyCode
  } = useI10n();
  const {
    displayPrice,
    fullPrice,
    discountPercentage
  } = getPrice(product.product, currencyCode);
  return <div key={product.id} className={classNames("w-full", index > 2 && !isVisible ? "hidden md:block" : "", vertical ? "" : "shadow-md rounded-md", styles.BestSellersWithTitleBlock)} data-sentry-component="BestSellersSingleBlock" data-sentry-source-file="BestSellersSingleBlock.tsx">
      <div className="flex flex-col justify-start w-full h-full md:justify-between">
        <div className={classNames("relative w-full mb-1 overflow-hidden bg-gray-100", vertical ? "aspect-square" : "aspect-video")}>
          <Link href={`/product/${product.product?.slug}`} className="relative block h-full" prefetch={false} data-sentry-element="Link" data-sentry-source-file="BestSellersSingleBlock.tsx">
            <Image src={product.image?.url as string} alt={product.product?.name as string} width={640} height={640} fill sizes="960px" className="object-cover object-top w-full h-full" loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} data-sentry-element="Image" data-sentry-source-file="BestSellersSingleBlock.tsx" />
          </Link>
        </div>
        <div className={classNames(vertical ? "" : "px-6")}>
          <Link href={`/product/${product.product?.slug}`} className={classNames(barlowCondensed.className, "uppercase font-semibold text-xl", vertical ? "" : "text-3xl")} prefetch={false} data-sentry-element="Link" data-sentry-source-file="BestSellersSingleBlock.tsx">
            {product.product?.name}
          </Link>
          <div className="flex flex-row pt-1">
            <p className={classNames(vertical ? "text-sm" : "text-xl", "text-[#6D6D6D] line-through")}>
              <Price value={fullPrice! * 100} data-sentry-element="Price" data-sentry-source-file="BestSellersSingleBlock.tsx" />
            </p>
            <p className={classNames(vertical ? "text-sm" : "text-xl", "ml-2 font-bold")}>
              <Price value={displayPrice! * 100} data-sentry-element="Price" data-sentry-source-file="BestSellersSingleBlock.tsx" />
            </p>
            <div className="relative inline-flex items-center justify-center pl-2">
              <div className={classNames(vertical ? "mr-0" : "scale-150 mr-0.5", "ml-1 border-solid border-r-[#1BB57C] border-r-8 border-y-transparent border-y-8 border-l-0")}></div>
              <div className={classNames("px-2 pt-0.5 bg-[#1BB57C] font-bold text-white whitespace-nowrap", vertical ? "text-xs h-4" : "text-base h-6")}>
                SAVE {discountPercentage}%
              </div>
            </div>
          </div>
          <ButtonServer content="Shop Now" url={`/product/${product.product?.slug}`} className={classNames("max-w-full  text-lg font-medium md:font-normal uppercase", vertical ? "mt-2" : "my-4 !py-6")} intent={ButtonIntent.DarkCTA} stretch={ButtonStretch.fixed} data-sentry-element="ButtonServer" data-sentry-source-file="BestSellersSingleBlock.tsx" />
        </div>
      </div>
    </div>;
};
export const BestSellersBigBlock = ({
  product
}: {
  product: BestSellersProductCard;
}) => {
  const {
    currencyCode
  } = useI10n();
  const {
    displayPrice,
    fullPrice,
    discountPercentage
  } = getPrice(product.product, currencyCode);
  return <Link href={`/product/${product.product?.slug}`} prefetch={false} data-sentry-element="Link" data-sentry-component="BestSellersBigBlock" data-sentry-source-file="BestSellersSingleBlock.tsx">
      <div className="w-full h-full overflow-hidden text-white ">
        <div className="relative w-full h-full">
          <div className={classNames("w-full h-full", styles.BestSellersBigBlock)} style={{
          backgroundImage: `url(${product.image?.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat"
        }} />
          <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black to-transparent">
            <div className="w-11/12 md:w-10/12 lg:w-9/12 py-5 pl-5">
              <Link href={`/product/${product.product?.slug}`} className={`${barlowCondensed.className} uppercase text-4xl lg:text-5xl font-semibold`} prefetch={false} data-sentry-element="Link" data-sentry-source-file="BestSellersSingleBlock.tsx">
                {product.product?.name}
              </Link>
              <div className="flex flex-row pt-2.5">
                <p className="text-xl font-thin text-gray-400 line-through">
                  <Price value={fullPrice! * 100} data-sentry-element="Price" data-sentry-source-file="BestSellersSingleBlock.tsx" />
                </p>
                <p className="ml-2 text-xl font-bold">
                  <Price value={displayPrice! * 100} data-sentry-element="Price" data-sentry-source-file="BestSellersSingleBlock.tsx" />
                </p>
                <div className="relative inline-flex items-center justify-center pl-2">
                  <div className="ml-1 mr-0.5 scale-150 border-solid border-r-[#1BB57C] border-r-8 border-y-transparent border-y-8 border-l-0"></div>
                  <div className="px-2 pt-0.5 h-6 bg-[#1BB57C] text-base font-bold text-white">
                    SAVE {discountPercentage}%
                  </div>
                </div>
              </div>
              <ButtonServer content="Shop Now" url={`/product/${product.product?.slug}`} className="w-11/6 md:w-10/12 lg:w-9/12 !py-6 my-4 text-lg !font-bold text-black uppercase bg-white hover:text-white" stretch={ButtonStretch.flexible} data-sentry-element="ButtonServer" data-sentry-source-file="BestSellersSingleBlock.tsx" />
            </div>
          </div>
        </div>
      </div>
    </Link>;
};
export default BestSellersSingleBlock;