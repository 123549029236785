"use client";

import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "../ContentBlock";
import { YouTubeEmbed } from "@next/third-parties/google";
import { ArrowLeftIcon } from "../ui/icons";
import { barlowCondensed } from "@/utils/fonts";
import { RippedPageSvg } from "../ui/RippedPage";
import { PeopleLogo, MountainIQLogo, MuscleFitnessLogo } from "../ui/logos";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./styles.module.css";
interface Props {
  block: Payload.YtVideoEmbedBlock;
}

/** GenAI Disclosure
 * Prompt: make me a pure tailwind version of the attached image (crop of figma design)
 * LLM: Vercel's v0.dev (https://v0.dev/chat/DehcHcFjOjN)
 **/
export const PressReleaseFeaturesBlock = ({
  block
}: Props) => {
  const headline = block.headline ?? "What the experts are saying";
  const content = [{
    quote: "This jacket exudes durability and quality craftsmanship all around, and earns major cool points for not breaking the bank.",
    source: "The 10 Best Men's Rain Jackets of 2024, According to Our Testers and Research",
    logo: <PeopleLogo />
  }, {
    quote: "BÆRSkin Tactical Supply has revolutionized outdoor gear with one of the best hoodies in the world.",
    source: "Camping Gifts For Him: 18 Gadget & Gear Ideas To Make His Day",
    logo: <MountainIQLogo />
  }, {
    quote: "The last outer layer you'll ever need, this tactical and stylish hoodie is perfect for outdoor winter adventures.",
    source: "10 Hoodie Holiday Must-Haves to Give (Or Receive) This Christmas",
    logo: <MuscleFitnessLogo />
  }];
  return <div className="relative" data-sentry-component="PressReleaseFeaturesBlock" data-sentry-source-file="PressReleaseFeatures.tsx">
      <ContentBlockServer className="flex flex-col items-center pt-8 pb-6 mb-8 text-white" fullWidthBgColor="bg-[#17181A]" data-sentry-element="ContentBlockServer" data-sentry-source-file="PressReleaseFeatures.tsx">
        <div className="p-8 text-white">
          <div className="max-w-6xl mx-auto">
            <h2 className={`${barlowCondensed.className} flex md:relative text-5xl uppercase tracking-[-0.02em] leading-tight`}>
              <ArrowLeftIcon className="hidden xl:block mr-2 md:mr-0 md:absolute w-9 rotate-180 -left-12 top-3 text-[#2B2C2E]" data-sentry-element="ArrowLeftIcon" data-sentry-source-file="PressReleaseFeatures.tsx" />
              <p>
                {headline.split(" ").slice(0, -3).join(" ")}
                <span className="ml-2 font-semibold">
                  {headline.split(" ").slice(-3).join(" ")}
                </span>
              </p>
            </h2>
            <p className="max-w-xl mt-2 mb-4">{block.text}</p>

            {/* <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
              <div className="relative lg:col-span-2">
                <div className="relative w-full mt-4 md:mb-4 xl:mt-6 xl:ml-5 xl:scale-105">
                  <YouTubeEmbed
                    style="width:100%; margin: 0;"
                    videoid={block.youtubeVideoId}
                    params="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  />
                </div>
              </div>
               <div className="flex flex-col justify-center">
                <blockquote
                  className={`${barlowCondensed.className} uppercase text-4xl md:text-6xl mb-4`}
                >
                  &ldquo;DEFINITELY{" "}
                  <span className="font-semibold">
                    MY FAVOURITE TACTICAL HOODIE
                  </span>{" "}
                  THUS FAR.&rdquo;
                </blockquote>
                <cite className="-mt-3 not-italic leading-tight">
                  Ryan Humphries
                  <br />
                  <span className="text-[#848484]">engearment.com</span>
                </cite>
              </div>
             </div> */}

            <div className="hidden grid-cols-3 gap-8 mt-8 lg:grid">
              {content.map((testimonial, index) => <div key={index} className={`pr-10 ${index < content.length - 1 && "border-white border-r-2 border-opacity-20"}`}>
                  <p className="text-2xl mb-4 min-h-40 xl:min-h-[134px] leading-[28px]">
                    &ldquo;{testimonial.quote}&rdquo;
                  </p>
                  <p className="my-2 leading-tight underline opacity-50 h-18 xl:h-12">
                    {testimonial.source}
                  </p>
                  <span className="mt-2.5 h-[45px]">{testimonial.logo}</span>
                </div>)}
            </div>
          </div>
        </div>

        <div className="w-full px-8 mx-auto mt-4 mb-8 lg:hidden">
          <Swiper slidesPerView={1.15} spaceBetween={25} className={`${styles.swiperContainer}`} slidesOffsetAfter={52} data-sentry-element="Swiper" data-sentry-source-file="PressReleaseFeatures.tsx">
            {content.map((testimonial, index) => <SwiperSlide key={index}>
                <div>
                  <p className="text-2xl mb-4 min-h-32 sm:min-h-8 md:min-h-16 leading-[28px]">
                    &ldquo;{testimonial.quote}&rdquo;
                  </p>
                  <p className="h-12 leading-tight underline opacity-50">
                    {testimonial.source}
                  </p>
                  <p className="mt-2.5">{testimonial.logo}</p>
                </div>
              </SwiperSlide>)}
          </Swiper>
        </div>
      </ContentBlockServer>

      <div className="absolute w-full -bottom-2 md:-bottom-4 xl:-bottom-8">
        <RippedPageSvg fill={"#17181A"} data-sentry-element="RippedPageSvg" data-sentry-source-file="PressReleaseFeatures.tsx" />
      </div>
    </div>;
};
export default PressReleaseFeaturesBlock;