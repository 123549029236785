"use client";

import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "./ContentBlock";
import { barlowCondensed, roboto } from "@/utils/fonts";
import { useState } from "react";
type Props = {
  block: Payload.FullWidthUGCImages;
};
export const FullWidthUGCImagesClient = ({
  block
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const headline = block.headline.trim().split(" ");
  const firstWord = headline.shift();
  const remainingWords = headline.join(" ");
  const displayImages = isVisible ? block.ugcImages : block.ugcImages?.slice(0, 3);
  const handleClick = () => {
    setIsVisible(!isVisible);
  };
  return <ContentBlockServer className="py-10 mb-4 text-gray-900" data-sentry-element="ContentBlockServer" data-sentry-component="FullWidthUGCImagesClient" data-sentry-source-file="FullWidthUGCImagesClient.tsx">
      <h2 className={`px-8 mb-6 text-5xl font-normal text-center uppercase ${barlowCondensed.className}`}>
        <span className="font-semibold">{firstWord}</span> {remainingWords}
      </h2>
      <ul className="relative grid grid-cols-2 md:grid-cols-3 mb-0">
        {displayImages?.map(item => <li key={`ugc-item-${item.id}`} className="w-full list-none aspect-square" style={{
        background: `url('${item.image.url}') `,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }} />)}
        {!isVisible && <div className="!z-0 absolute inset-0 bg-gradient-to-t from-35% from-white via-70% via-transparent" />}
      </ul>
      <button onClick={handleClick} className={`relative z-10 flex justify-center mx-auto p-3 text-white bg-black w-[262px] rounded-md ${roboto.className} ${isVisible ? "mt-8" : "-mt-16"}`}>
        VIEW {isVisible ? "LESS" : "MORE"}
      </button>
    </ContentBlockServer>;
};
export default FullWidthUGCImagesClient;