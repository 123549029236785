"use client";

import { CreditCards } from "@/components/CreditCards";
import Image from "@/components/Image";
import { MINISTORE } from "@/lib/const";
import type { Payload } from "@local/payload-client/src/types";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
import { classNames } from "@local/utils/src/classNames";
import { ContentBlockServer } from "./ContentBlock";
import type { RatingScores } from "./Reviews/Stars";
import { StarsWithReviews } from "./Reviews/StarsWithReviews";
import ButtonServer, { ButtonIntent, ButtonStretch } from "./ui/button";
import MuxPlayer from "@mux/mux-player-react";
import { barlowCondensed } from "@/utils/fonts";
import HydrationContainer from "./HydrationContainer";
interface Props {
  block: Payload.PromoHeadlineVideoWithStarsBlock;
}
export const PromoHeadlineVideoWithStarsServer = ({
  block
}: {
  block?: Payload.PromoHeadlineVideoWithStarsBlock;
}) => {
  if (block) {
    return <div>
        <ContentBlockServer className="relative flex justify-center" touchEdges>
          <VideoAndImage block={block} />
          <TextContent block={block} />
        </ContentBlockServer>
      </div>;
  } else {
    // TODO: add loading state
    return <>Loading...</>;
  }
};
export default PromoHeadlineVideoWithStarsServer;
const VideoAndImage = ({
  block
}: Props) => {
  return <>
      <div className={classNames("absolute top-0 z-30 w-full h-1/2 sm:h-full bg-opacity-50 ", block.brightBackground ? "" : "bg-black")} />
      {block.videoId ? <HydrationContainer>
          <MuxPlayer autoPlay="muted" defaultHiddenCaptions loop metadataVideoTitle={block.headline} muted nohotkeys playbackId={block.videoId} poster={block.fallbackImage.url} streamType="on-demand" maxResolution="720p" />
          <style>
            {`
              mux-player {
                --controls: none;
                --media-object-fit: cover;
                position: absolute;
                inset: 0;
                z-index: 25;
                height: 50%;
              }
              mux-player::part(gesture-layer) {
                display: none;
              }
              @media (min-width: 640px) {
                mux-player {
                  height: 100%;
                }
              }
            `}
          </style>
        </HydrationContainer> : null}
      <Image alt={block.headline || ""} height={block.fallbackImage.height} src={block.fallbackImage.url} width={block.fallbackImage.width} className="absolute top-0 z-20 object-cover w-full h-1/2 sm:h-full" loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} data-sentry-element="Image" data-sentry-source-file="PromoHeadlineVideoWithStars.tsx" />
    </>;
};
const TextContent = ({
  block
}: Props) => {
  return <>
      <div className="sm:hidden absolute z-30 top-[260px] bg-gradient-to-b from-transparent via-[#0D0E0F] to-[#0D0E0F] h-32 w-full" />
      <div className="relative bg-[#0D0E0F] w-full px-6 py-10 lg:py-20">
        <div className={classNames("z-10 flex flex-col lg:my-10 w-full justify-start items-start max-w-7xl md:px-16 mx-auto",
      // block.alignLeft ? "items-start" : "items-center",
      block.brightBackground ? "text-gray-900" : "text-white")}>
          <div className="sm:hidden h-[260px] z-0" />
          {block.headline && <h2 className={classNames("mb-4 font-medium uppercase text-6xl leading-tighter z-40", barlowCondensed.className,
        // block.subtitle ? "text-3xl" : "text-5xl",
        // block.alignLeft ? "justify-start" : "justify-center"
        true ? "w-fit" : "w-full md:w-4/5")} dangerouslySetInnerHTML={{
          __html: block.headline
        }} />}
          <p className="mb-5 text-base font-normal leading-[1.333rem] max-w-[424px] z-40">
            {block.subtitle}
          </p>
          {block.ctaContent && <div className="z-40">
              <ButtonServer content={block.ctaContent} url={block.ctaUrl} intent={ButtonIntent.CTA} stretch={ButtonStretch.flexible} className="uppercase font-medium" />
            </div>}
          {block.showReviewStars && <div className="-ml-12 mb-5 mt-2 max-w-fit md:max-w-none z-40">
              <StarsWithReviews rating={parseFloat(block.starsRating as string) as RatingScores} reviews={block.reviewsAmount} overrideColor={MINISTORE === "baerskintactical" ? "#1BB57C" : "#fbbf24"} dark />
            </div>}
          {block.showCreditCards && <div className="max-w-[500px] mx-auto md:mx-0 bg-white rounded-full px-2 py-1">
              <CreditCards fillHeight />
            </div>}
        </div>
      </div>
    </>;
};